import React from 'react'

import { Box, Flex } from '@rebass/emotion'

import { HeroHeadline } from '../components/atoms'
import { Layout } from '../components/layouts/Layout'
import { ConstrainedWidthContainer } from '../components/molecules'
import { LegalCenterNav, SEO } from '../components/organisms'
import styled from '../styles/styled'

const Content = styled.div`
  overflow-wrap: word-wrap;

  table {
    max-width: 100%;
    overflow-x: auto;
    display: block;
    border-radius: ${props => props.theme.borderRadius};
    box-shadow: ${props => props.theme.cardBoxShadow};
    margin-bottom: 1rem;
  }

  thead tr,
  tbody tr:not(:last-child) {
    border-bottom: 2px solid ${props => props.theme.colors.darkGray};
  }

  tbody tr:nth-of-type(2n + 1) {
    background: ${props => props.theme.colors.white};
  }

  thead tr,
  tbody tr:nth-of-type(2n) {
    background: ${props => props.theme.colors.lightBlue};
  }

  th,
  td {
    padding: 0.5rem 1rem;
  }

  a {
    color: ${props => props.theme.colors.darkBlue};
  }

  h1 {
    margin-bottom: 1rem;
  }

  ul {
    display: list-item;
    list-style-type: none;
    list-style-position: outside;
    margin-left: 1rem;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  ol {
    list-style-position: outside;
    margin-left: 1rem;
    padding-left: 0;
    margin-bottom: 1rem;
  }

  li {
    display: list-item;
    line-height: 1.5;
    padding-left: 0;
    margin-left: 1rem;
  }

  ul > li {
    list-style-type: disc;
  }
`

const LegalCenter = ({ pageContext: { page } }) => {
  return (
    <Layout>
      <SEO title={page.title} />

      <ConstrainedWidthContainer>
        <Flex flexWrap="wrap" px={[0, 5]} justifyContent="space-between">
          <Box width={[1, 1, 1, 3 / 12]}>
            <LegalCenterNav />
          </Box>

          <Box width={[1, 1, 1, 8 / 12]} pb={5}>
            <HeroHeadline>{page.title}</HeroHeadline>

            <Content
              dangerouslySetInnerHTML={{
                __html: page.content,
              }}
            />
          </Box>
        </Flex>
      </ConstrainedWidthContainer>
    </Layout>
  )
}

export default LegalCenter
